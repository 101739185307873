* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header-section {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 10px;
}

.header h1 {
    font-size: 35px;
    font-weight: bold;
    font-family: monospace;
}

.header h2 {
    font-size: 20px;
    font-family: monospace;
}

.whatsapp-icon {
    font-size: 75px;
    color: yellowgreen;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 20px;
    cursor: pointer;
}

.whatsapp-icon:hover {
    color: green;
    transition: .5s;
}

@media screen and (max-width:768px) {
    .header-section {
        height: auto;
    }

    .header-section h1 {
        font-size: 15px;
    }

    .header-section h2 {
        font-size: 10px;
    }
}