.welcome-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    height: 90vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../images/welcomebg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    letter-spacing: 2px;
}

.welcome-section h1 {
    font-size: 40px;
    font-family: monospace;
    width: 75%;
    margin: 25px auto;
    color: #fff;
    text-shadow: 4px 4px 4px #000;
}

.welcome-section h2 {
    margin-top: 50px;
    color: #fff;
    text-shadow: 4px 4px 4px #000;
    width: 50%;
    margin: 0 auto;
    font-family: monospace;
}

.welcome-section span {
    color: orange;
    font-size: 35px;
    font-weight: bold;
}

.btnGetStarted {
    padding: 15px;
    margin-top: 20px;
    width: 200px;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: none;
    border-radius: 100px;
    color: #fff;
    background-color: green;
    cursor: pointer;
    font-family: monospace;
}

.btnGetStarted:hover {
    background-color: yellowgreen;
    transition: .5s all ease-in-out;
    color: #000;
    box-shadow: 2px 2px 2px #000;
}

@media screen and (max-width:768px) {
    .welcome-section {
        height: 100vh;
        padding: 20px;
    }

    .welcome-section h1 {
        width: 100%;
        font-size: 25px;
        margin-top: -50px;
    }

    .welcome-section h2 {
        font-size: 20px;
        width: 100%;
        margin-top: 50px;
    }

    .welcome-section span {
        font-size: 25px;
    }

    .btnGetStarted {
        width: 55%;
        font-size: 15px;
    }
}