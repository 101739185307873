.section3-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../images/section3bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section3-section h1 {
    color: #fff;
    font-size: 50px;
    font-family: fantasy;
    text-shadow: 4px 4px 4px #000;
}

.section3-section h2 {
    color: #fff;
    font-size: 25px;
    text-shadow: 4px 4px 4px #000;
    font-family: monospace;
}

.section3-card {
    display: inline-block;
    width: 25%;
    margin: 20px;
    vertical-align: top;
    color: #fff;
    letter-spacing: 1px;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(255, 255, 0, 0.5);
    box-shadow: 4px 4px 4px #000;
}

.section3-card h3 {
    font-size: 25px;
    font-family: monospace;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 2px 2px 2px #000;
}

.section3-card p {
    font-size: 20px;
    font-family: monospace;
    margin-top: 10px;
    font-weight: 500;
    text-align: center;
    text-shadow: 1px 1px 1px gray;
    color: #000;
    font-weight: bolder;
}

.section3-card span {
    color: maroon;
    font-weight: bolder;
    font-size: 30px;
    text-shadow: 1.5px 1.5px 1.5px #000;
}

@media screen and (max-width:768px) {
    .section3-section {
        height: auto;
        padding: 20px;
    }

    .section3-section h1 {
        font-size: 35px;
    }

    .section3-section h2 {
        font-size: 20px;
    }

    .section3-card h3 {
        font-size: 20px;
    }

    .section3-card p {
        font-size: 15px;
    }

    .section3-card span {
        font-size: 20px;
    }

    .section3-card {
        display: block;
        width: 100%;
        margin: 20px auto;
    }
}