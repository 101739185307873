* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 2px;
}

.section5-section {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 1px;
}

.section5 h1 {
    color: #fff;
    font-size: 50px;
    text-shadow: 4px 4px 4px #000;
    font-family: monospace;
    margin-top: 20px;
}

.section5 p {
    color: #fff;
    font-size: 25px;
    text-shadow: 4px 4px 4px #000;
    font-family: monospace;
}

.section5-section span {
    color: orange;
    font-size: 25px;
    font-weight: bold;
}

.btnStartNow {
    padding: 15px;
    margin: 20px;
    width: 200px;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: none;
    border-radius: 100px;
    color: #fff;
    background-color: green;
    cursor: pointer;
    font-family: monospace;
}

.btnStartNow:hover {
    background-color: yellowgreen;
    transition: .5s all ease-in-out;
    color: #000;
    box-shadow: 2px 2px 2px #fff;
}

@media screen and (max-width:768px) {
    .section5-section {
        height: auto;
        padding: 10px;
    }

    .section5-section h1 {
        font-size: 25px;
    }

    .section5-section p {
        font-size: 15px;
    }

    .btnStartNow {
        width: 45%;
        font-size: 15px;
    }
}